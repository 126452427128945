import React from 'react'

const ArrowRight = ({ fill = '#EB3E3E' }) => {
  return (
    <svg height='20' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.6 6L0 6V8L12.6 8L7.7 12.7L9 14L16 7L9 0L7.7 1.3L12.6 6Z'
        fill={fill}
      />
    </svg>
  )
}

export default ArrowRight
