import React from 'react'
import { observer } from 'mobx-react-lite'

import Head from 'components/Head'
import SiteLayout from 'components/SiteLayout'
import ArrowRight from 'icons/ArrowRight'

import { usePageView } from 'hooks'
import THEME from 'utils/theme'
import { EventsStore } from 'stores/events'
import { TYPE_FORMS } from 'constants/typeform'

const ParticipatePage = observer(() => {
  // const event = events?.[0]
  const event = {
    name: 'RNR25 in Wheeling, WV',
    metadata: {
      fighter_signup_url: TYPE_FORMS.fighter_signup_url,
      ring_girl_signup_url: TYPE_FORMS.ring_girl_signup_url,
      fighter_signup_active: true,
      ring_girl_signup_active: true
    }
  }
  usePageView()

  return (
    <>
      <SiteLayout background={THEME.COLORS.BACKGROUND_GRAY}>
        <Head
          title='Sign Up To Fight Or Be A Ring Girl | Rough N’ Rowdy'
          description='Want to fight in Rough n Rowdy? Want to enter the Ring Girl contest? Visit this page to register for upcoming RNR events.'
        />
        {event ? (
          <div className='container'>
            <h1 className='title'>Sign Up For {event.name}</h1>
            <p className='subtitle'>
              Our next event is Friday, August 9th.{' '}
              <a
                href='https://www.etix.com/ticket/p/48642324/rough-n-rowdy-25-wheeling-wesbanco-ar'
                className='tickets-link'
                target='_blank'
                rel='noopener noreferrer'
              >
                Buy tickets here
              </a>
            </p>
            <div className='panel-container'>
              {event.metadata.fighter_signup_active ? (
                <a
                  className='panel panel--fighter'
                  href={event?.metadata?.fighter_signup_url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='/static/images/join-fighter.png' alt='fighter signup image' />
                  <div className='gradient' />

                  <div className='panel__cta'>
                    <span className='panel__text'>Fighter Sign Up</span>
                    <ArrowRight />
                  </div>
                </a>
              ) : null}
              <div className='panel__spacer' />
              {event.metadata.ring_girl_signup_active ? (
                <a
                  href={event?.metadata?.ring_girl_signup_url}
                  className='panel panel--ringGirl'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='/static/images/join-ring-girl.png' alt='ring girl signup image' />
                  <div className='gradient' />
                  <div className='panel__cta'>
                    <span className='panel__text'>Ring Girl Sign Up</span>
                    <ArrowRight />
                  </div>
                </a>
              ) : null}
            </div>
          </div>
        ) : (
          <div className='container textCenter'>
            <h1 className='title'>No upcoming events</h1>
            <p className='subtitle'>Check back soon to sign up to participate in Rough N' Rowdy.</p>
          </div>
        )}
      </SiteLayout>
      <style jsx>{`
        .container {
          max-width: 800px;
          padding: 3rem 0;
          margin: 0 auto;
        }
        @media (max-width: 960px) {
          .container {
            padding: 3rem 10px;
          }
        }
        @media (max-width: 767px) {
          .container {
            padding: 2rem 10px;
          }
        }
        @media (max-width: 600px) {
          .container {
            padding: 1rem 10px;
          }
        }
        .title {
          margin-top: 0;
          margin-bottom: 5px;
          padding: 0;
          color: ${THEME.COLORS.TEXTCOLOR};
          font-family: ${THEME.FONTS.HELVETICA};
          font-size: 32px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 40px;
        }
        .subtitle {
          margin-top: 0;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 18px;
        }
        .panel-container {
          display: flex;
          justify-content: center;
        }
        .panel__spacer {
          width: 15px;
          height: 15px;
        }
        @media (max-width: 600px) {
          .panel-container {
            flex-direction: column;
            align-items: center;
          }
        }
        .panel {
          position: relative;
          width: 400px;
          max-width: 100%;
          height: 228px;
          background-size: contain;
        }
        .panel .gradient {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 52.51%, #000000 77.19%);
        }
        .panel img {
          object-fit: contain;
        }
        .panel__cta {
          position: absolute;
          left: 15px;
          bottom: 15px;
          cursor: pointer;
        }
        .panel__text {
          font-family: ${THEME.FONTS.BEBAS};
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          line-height: 44px;
          color: #ffffff;
          margin-right: 10px;
          display: inline-block;
        }
        .panel__title {
          font-family: ${THEME.FONTS.HELVETICA};
          font-weight: bold;
          font-size: 32px;
          line-height: 39px;
          letter-spacing: -0.1px;
          margin: 0 0 32px;
        }
        .tickets-link {
          color: ${THEME.COLORS.LINKCOLOR};
        }
        .tickets-link:hover {
          text-decoration: underline;
        }
      `}</style>
    </>
  )
})

export async function getStaticProps() {
  const events = await EventsStore().findRNR({
    status: 'presale,upcoming,live'
  })
  return {
    props: { events },
    revalidate: 1
  }
}

export default ParticipatePage
